import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";

function Terms(){
return(
    <>
    
    <Innernavbar />
     <section className="our_servces">
  <div className="container">





  </div>
</section>


<div class="container terms">
    <header>
      <h1>Terms & Conditions</h1>
    </header>

    <section>
      <h2>1. Introduction</h2>
      <p>Welcome to our website. By accessing and using this website, you accept and agree to comply with the following terms and conditions.</p>
    </section>

    <section>
      <h2>2. Definitions</h2>
      <p><strong>User:</strong> Any visitor or customer accessing the website.<br />
         <strong>Website:</strong> Refers to our ecommerce platform.<br />
         <strong>Products:</strong> Goods or services available for purchase on the website.</p>
    </section>
    <section>
      <h2>3. Website Usage</h2>
      <p><strong>Access and Use:</strong> You must be at least 18 years old to use this website. Access to certain areas may require registration.</p>
      <p><strong>Account:</strong> If you create an account, you are responsible for maintaining its confidentiality and agree to notify us of any unauthorized use.</p>
    </section>
    <section>
      <h2>4. Products and Services</h2>
      <p><strong>Product Listings:</strong> We strive to provide accurate descriptions, pricing, and availability of products. However, we do not guarantee that all information is accurate, complete, or current.</p>
      <p><strong>Orders:</strong> Placing an order constitutes an offer to purchase the product at the price and terms stated. We reserve the right to refuse or cancel any order for any reason.</p>
    </section>
   
    <footer>
      <p>These terms and conditions were last updated on <span id="lastUpdated">27-06-2024</span>.</p>
      <p>Contact us at <a href="mailto:info@reliep.com">info@reliep.com</a> for any questions or concerns.</p>
    </footer>
  </div>


 <Footer />
    </>
)
}
export default Terms;