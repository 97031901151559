import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import './output.css';

function PartnerStore(){
    return(
        <>
         
    
    
         <nav class="bg-white shadow-lg fixed top-0 left-0 right-0 z-50 md:relative md:z-auto">
        <div class="max-w-7xl mx-auto px-2 md:px-4">
            <div class="flex justify-between items-center">
       
                <div class="flex space-x-4">
                    <a href="#" class="flex items-center py-5 px-2 text-gray-700">
                        <img src="/assets/images/images/logo.png" alt="Logo" class="h-8 w-16 mr-2" />
                    </a>
                </div>

          
                <div class="hidden md:flex items-center space-x-1">
                    <a href="#" class="py-5 px-3 text-gray-700 hover:text-gray-900">Home</a>
                    <a href="#" class="py-5 px-3 text-gray-700 hover:text-gray-900">About</a>
                    <a href="#" class="py-5 px-3 text-gray-700 hover:text-gray-900">Services</a>
                    <a href="#" class="py-5 px-3 text-gray-700 hover:text-gray-900">Blog</a>
                    <a href="#" class="py-5 px-3 text-gray-700 hover:text-gray-900">Contact</a>
                </div>

  
                <div class="md:hidden flex items-center">
                    <button class="mobile-menu-button">
                        <svg class="hamburger-icon w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                        <svg class="close-icon hidden w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>


        <div class="mobile-menu hidden md:hidden">
            <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Home</a>
            <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">About</a>
            <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Services</a>
            <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Blog</a>
            <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Contact</a>
        </div>
    </nav>


    <div class="relative max-w-7xl mx-auto px-2 md:px-4 mt-20 md:mt-4">
        <div class="owl-carousel owl-theme">
            <div class="item">
                <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{"backgroundImage": "url('/images/hero-subimage4.png')"}}></div>
            </div>
            <div class="item">
                <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{"backgroundImage": "url('/images/hero-subimage2.png')"}}></div>
            </div>
            <div class="item">
                <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{"backgroundImage": "url('/images/hero-subimage3.png')"}}></div>
            </div>
            <div class="item">
                <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{"backgroundImage": "url('/images/hero-subimage4.png')"}}></div>
            </div>
        </div>
    </div>


    <div class="max-w-7xl mx-auto px-2 md:px-4 py-10 mb-4"> 
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            
            <div class="flex flex-col gap-4 md:col-span-2">
                <div class=" bg-white border border-[#D9D9D9] rounded-xl p-2 md:p-6  overflow-y-auto">

                    <div class="flex flex-col gap-4">
                        <div>
                            <h2 class="text-2xl font-bold mb-4">About </h2>
                            <p class=" mb-4">
                                Welcome to Krishna Carpenters! With a rich tradition of craftsmanship and a passion for quality, we bring your woodworking visions to life. Our expert team combines years of experience with innovative techniques to deliver exceptional custom furniture, home renovations, and repairs.
                            </p>
                            <p class=" mb-4">
                                We take pride in our attention to detail and personalized service, ensuring each project reflects your unique style and needs. From elegant cabinetry to durable structures, our commitment to excellence and customer satisfaction drives everything we do.
                            </p>
                            <p class=" mb-4">
                                Experience the art of fine carpentry with Krishna Carpenters, where every creation is crafted with precision and care.
                            </p>
                        </div>
                        
                        <div>
                            <h2 class="text-2xl font-bold mb-4">Availability  </h2>
                            <div class="flex gap-10 md:gap-20">
                                <div>
                                    <p class="mb-2">Weekly Schedule</p>
                                    <p class=" text-[#850C85]">Monday - Saturday</p>
                                </div>

                                <div>
                                    <p class="mb-2">Time</p>
                                    <p class=" text-[#850C85]">09:00 AM  - 10:00 PM</p>
                                </div>

                            </div>
                        </div>

                 
                        <div>
                            <h2 class="text-2xl font-bold mb-4">Property Location</h2>
                            <div class="w-full h-[300px] rounded-xl overflow-hidden mb-4">
                                
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.155187274143!2d88.42881167433687!3d22.573298432946515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275b0ceb4a29d%3A0xcd820a49580a5d39!2sEN-34%20Building!5e0!3m2!1sen!2sin!4v1726548414322!5m2!1sen!2sin"  
                                    width="100%" height="300" style={{"border":"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                            <div class="flex gap-2">
                                <img src="/assets/images/images/location.png" alt="" class="h-6 w-6" />
                                <p class="text-[#555555]">Rajarhat, West Bengal, 7000102, India</p>
                            </div>
                        </div>

                     
                         <div>
                            <h2 class="text-2xl font-bold mb-4">Services & Details</h2>
                            <div class="flex flex-col md:flex-row gap-4 md:gap-20">
                                <div>
                                    <h3 class="text-lg font-bold mb-2">Furniture Available</h3>
                                    <div class="flex gap-10">
                                        <div class="flex flex-col gap-2">
                                            <p>Bed</p>
                                            <p>Dining Table</p>
                                            <p>Study Table</p>
                                            <p>Almirah</p>
                                            <p>Locker</p>
                                            <p>Wooden Crafts</p>

                                        </div>
                                        <div class="flex flex-col gap-2">
                                            <p>Chair</p>
                                            <p>Interior Decor</p>
                                            <p>Panel</p>
                                            <p>Door</p>
                                            <p>Wooden Utensil</p>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 class="text-lg font-bold mb-2">Materials Available</h3>
                                    <div class="flex gap-10">
                                        <div class="flex flex-col gap-2">
                                            <p>Bed</p>
                                            <p>Dining Table</p>
                                            <p>Study Table</p>
                                            <p>Almirah</p>
                                            <p>Locker</p>
                                            <p>Wooden Crafts</p>

                                        </div>
                                        <div class="flex flex-col gap-2">
                                            <p>Chair</p>
                                            <p>Interior Decor</p>
                                            <p>Panel</p>
                                            <p>Door</p>
                                            <p>Wooden Utensil</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>


                    </div>

                    
                    
                </div>
    
                <div class=" bg-white  p-2 md:p-6  overflow-y-auto">
                    <h2 class="text-2xl font-bold mb-4">Review & Ratings</h2>
                    <div class="flex justify-between mb-4">
                        <div class="flex gap-2 align-bottom items-center">
                            <h1 class="text-4xl font-bold">4.8</h1>
                            <img src="/assets/images/images/Star.png" alt="" class="h-4 w-4" />
                            <p>(1248 Ratings)</p>
                        </div>
                        <div>
                            <button class="bg-[#850C85] text-white font-semibold py-2 px-6 rounded-xl shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out">
                                Add Your Review
                            </button>
                        </div>
                        
                    </div>
                    
                    <div>
                        <div class="review-carousel owl-carousel owl-theme">
                      
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                         
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                       
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                    
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                    
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                            
                      
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                     
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                           
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                 
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                            
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                             
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                        
                    
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                          
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                              
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                         
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                         
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                        
                     
                        </div>
                        
                    </div>
                </div>


             
                <div class=" bg-white  p-2 md:p-6  overflow-y-auto">
                    <h2 class="text-2xl font-bold mb-4">Cancellation Policy</h2>
                    <div class="flex gap-2 align-middle">
                        <img src="/assets/images/images/cancellation.png" alt="" class="h-5 w-5" />
                        <p class="text-[#E94444]">You will incur a ₹10 charge if you cancel the order after it has been accepted by the
                            vendor.</p>
                    </div>

                   
                    
                </div>
            </div>

    
         
            <div class="  h-fit  sticky bg-none top-5">
                <div class="bg-white shadow-lg rounded-xl mb-4 p-2 md:p-6">
                    <h2 class="text-2xl font-bold mb-4 ">Store Details</h2>
                    <p class="font-semibold mb-2 text-md">Krishna Carpenters </p>
                    <p class="mb-2 text-md"><span class="font-semibold">Owner : </span>Josef Jaz</p>
                    <p class="mb-2 text-md"><span class="font-semibold">Joined on : </span>July 26, 2018</p>
                    <p class="mb-2 text-md"><span class="font-semibold">GSTIN : </span>19AFRDRS7838L1ZQ</p>
                    <p class="mb-2 text-md"><span class="font-semibold">Address : </span>DC248, Krishnanagar, Rajarhat, West Bengal, 7000102, India</p>
                    <div class="my-4"><hr /></div>
                    <h3 class="text-xl font-bold mb-4">Share</h3>
                    <div class="flex justify-center md:justify-start space-x-4">
                  
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://yourwebsite.com" target="_blank">
                                <img src="/assets/images/images/facebook.png" alt="Facebook" class="w-8 h-8" />
                            </a>
                            <p class="text-sm font-semibold">Facebook</p>
                        </div>
                
                  
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <a href="https://wa.me/?text=https://yourwebsite.com" target="_blank">
                                <img src="/assets/images/images/whatsapp-logo.png" alt="WhatsApp" class="w-8 h-8" />
                            </a>
                            <p class="text-sm font-semibold">WhatsApp</p>
                        </div>
                
                 
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <button onclick="copyToClipboard()">
                                <img src="/assets/images/images/copy-link-logo.png" alt="Copy Link" class="w-8 h-8" />
                            </button>
                            <p class="text-sm font-semibold">Copy Link</p>
                        </div>
                    </div>
                </div>

                <div class="rounded-xl">
                    <img src="/assets/images/images/reward.png" alt="" />
                </div>

               
            </div>
        </div>
    </div>



    




<div class="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 p-4  rounded-t-2xl border-t border-purple-500 flex flex-col md:flex-row md:justify-between">


    <div class="w-full md:w-1/2 mb-4 md:mb-0 flex flex-col space-y-2">
   
        <div class="text-2xl font-bold">Krishna Carpenters</div>
     
         <div class="flex gap-4">
            <div class="flex items-center space-x-2 ">
                <img src="/assets/images/images/Star.png" alt="Star Rating" class="w-5 h-5" />
                <span class="text-sm font-semibold"><p class="text-[#555555]">4.8/5 (1265 reviews)</p></span>
            </div>
          
            <div class="text-sm text-gray-700"><span class="text-[#28892D]">Opens at</span> 09:00 AM</div>
         </div>

    </div>

  
    <div class="w-full md:w-1/2 flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
    
        <button class="bg-[#850C85] text-white py-2 px-4 rounded-xl flex items-center justify-center w-full md:w-1/2  transition duration-300">
            <img src="/assets/images/images/book-now.png" alt="Book Now Icon" class="w-5 h-5 mr-2" />
            <span>Book Now</span>
        </button>

      
        <button class="bg-white text-[#850C85] py-2 px-4 rounded-xl flex items-center justify-center w-full md:w-1/2 border border-[#850C85]  transition duration-300">
            <img src="/assets/images/images/notify-vendor.png" alt="Notify Me Icon" class="w-5 h-5 mr-2" />
            <span>Notify Me</span>
        </button>
    </div>
</div>
   

    

     <Footer />
        </>
    )
}
export default PartnerStore;